import React, {useEffect} from 'react';
import {useModal} from "../../hooks/useModal";
import Image from 'next/image'
import {useTranslation} from "react-i18next";

const ExpoBannerContent = () => {
  const {t} = useTranslation();

  return (
    <div
      className="relative font-['Raleway'] max-w-[800px] md:min-w-[680px] flex gap-y-2 flex-col items-center mx-auto bg-zinc-800 text-white py-8 rounded-xl overflow-hidden text-center max-md:px-2 max-md:bg-[length:120px_120px,_120px_120px] bg-[url('/assets/images/backgrounds/expo-banner/expo-banner.svg'),_url('/assets/images/backgrounds/expo-banner/expo-banner-right.svg')] bg-[position:left_bottom,_right_top]  bg-no-repeat">
      <Image src={"/assets/images/backgrounds/expo-banner/logo-hearx-light.png"} width={145} height={44}
             alt={t('index.expo-banner.hearx-logo-alt')} className="mx-auto"/>
      <h1 className="text-4xl font-extrabold mt-6">{t('index.expo-banner.heading')}</h1>
      <p className="text-lg text-[#80DEEA] font-bold -mt-2">{t('index.expo-banner.subheading')}</p>
      <p className="text-sm my-2 mt-3">{t('index.expo-banner.intro-text')}</p>
      <div className="text-neutral-300">
        <p className="text-sm">{t('index.expo-banner.visit-booth')}</p>
        <p className="text-sm mb-4 max-w-sm">
          {t('index.expo-banner.learn-more')}
        </p>
      </div>
      <a href={'https://calendly.com/ewald-prinsloo/nsc'} target="_blank">
        <button
          className="bg-[#81DEEA] border-2 border-white hover:bg-teal-300 text-white py-1 px-8 rounded-full text-sm font-semibold">
          {t('index.expo-banner.button-text')}
        </button>
      </a>
      <p className="text-[#81DEEA]">{t('index.expo-banner.event-code')}</p>
    </div>
  );
};

function ExpoBannerPopup() {
  const {modal, open, close} = useModal({
    content: () => {
      return (<ExpoBannerContent/>);
    },
  });

  useEffect(() => {
    open();
  }, []);

  return modal
}

export default ExpoBannerPopup;
