import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Modal } from "../components/ui/modal";

export interface useModalProps {
  header?: React.ReactNode;
  content:
    | React.ReactNode
    | ((props: {
        modalOpen: boolean;
        open: () => void;
        close: () => void;
      }) => React.ReactNode);
}

export const useModal = ({ content, header }: useModalProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
  const toggle = (value?: boolean) => setModalOpen(value ?? !modalOpen);

  const modal = (
    <AnimatePresence>
      {modalOpen && (
        <Modal modalOpen={modalOpen} handleClose={close} header={header}>
          {typeof content === "function"
            ? content({ modalOpen, open, close })
            : content}
        </Modal>
      )}
    </AnimatePresence>
  );

  return { modal, modalOpen, open, close, toggle };
};
