import { motion } from "framer-motion";
import styles from "./modal.module.scss";
import { Modal as MuiModal } from "@mui/material";
import { AiFillCloseCircle } from "react-icons/ai";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
    scale: 1,
  },
  visible: {
    y: "0",
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 100,
      stiffness: 1000,
    },
  },
  exit: {
    y: "100vh",
    scale: 0,
    opacity: 0,
  },
};

interface ModalProps {
  modalOpen: boolean;
  children: React.ReactNode;
  header?: React.ReactNode;
  handleClose?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  modalOpen,
  handleClose,
  header,
  children,
}) => {
  return (
    <MuiModal open={modalOpen} onClose={handleClose}>
      <div className={styles.root} onClick={handleClose}>
        <motion.div
          className={styles.modal}
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className={styles.header}>{header}</div>
          <div className={styles.content}>
            <button onClick={handleClose} className="mb-4">
              <AiFillCloseCircle size="2rem" color="white" />
            </button>
            {children}
          </div>
        </motion.div>
      </div>
    </MuiModal>
  );
};
