import classNames from "classnames";
import styles from "./hero-promo-banner.module.scss";

export type HeroPromoBannerProps = {
  className?: string;
};

export const HeroPromoBanner: React.FC<HeroPromoBannerProps> = ({
  className,
}) => {
  return (
    <a href="/hearoae">
      <div className={classNames(styles.root, className)}>
        <h2 className={styles.title}>
          <div>New NextGen OAE device</div>
          <div>Exclusive pre-order pricing</div>
        </h2>
        <img
          src={"/assets/images/pages/hearoae/ic_heartest.png"}
          alt={"System and Organization Controls attestation Badge"}
          className={styles.logo}
        />
        <button className={styles.button}>Learn more</button>
        <div className={styles.footer}>
          <div>
            Available only from hearX<sup>®</sup>
          </div>
          <div>
            <div>Shipments and deliveries available from Fall 2024.</div>
            <div>Terms and conditions apply.</div>
          </div>
        </div>
      </div>
    </a>
  );
};
